import NewBackup from '@/layouts/VPS/components/NewBackup.vue';
import DeleteBackup from '@/layouts/VPS/components/DeleteBackup.vue';
import RecoverBackup from '@/layouts/VPS/components/RecoverBackup.vue';

export default {
  methods: {
    createBackup(title, props = {}) {
      let self = null;
      this.$modals.open({
        name: 'BackupModal',
        size: 'big',
        component: NewBackup,
        closeOnBackdrop: false,
        title: title,
        props: props,
        on: {},
        onOpen: instance => (self = instance),
        onClose: () => {
          this.$store.getters['moduleVPS/moduleVpsOrder/listBackups'];
        },
      });
    },
    recoverBackup(title, props = {}) {
      let self = null;
      this.$modals.open({
        name: 'RecoverCurrentBackup',
        size: 'big',
        component: RecoverBackup,
        closeOnBackdrop: false,
        props: props,
        title: title,
        on: {},
        onOpen: instance => (self = instance),
        onClose: () => {
          this.$store.getters['moduleVPS/moduleVpsOrder/listBackups'];
        },
      });
    },
    deleteBackup(title, props = {}) {
      let self = null;
      this.$modals.open({
        name: 'DeleteCurrentBackup',
        size: 'big',
        component: DeleteBackup,
        closeOnBackdrop: false,
        title: title,
        props: props,
        on: {},
        onOpen: instance => (self = instance),
        onClose: () => {
          this.$store.getters['moduleVPS/moduleVpsOrder/listBackups'];
        },
      });
    },
    // deleteBackup(props = {}) {
    //   let self = null;
    //   this.$modals.open({
    //     name: 'DeleteCurrentBackup',
    //     size: 'big',
    //     component: DeleteBackup,
    //     closeOnBackdrop: false,
    //     props: props,
    //     title: this.$t('delete_backup'),
    //     on: {},
    //     onOpen: instance => (self = instance),
    //     onClose: () => {
    //       this.$store.getters['moduleVPS/moduleVpsOrder/listBackups'];
    //     },
    //   });
    // },
  },
};
